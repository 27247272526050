import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/templates/content.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Equation = makeShortcode("Equation");
const Callout = makeShortcode("Callout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Waves`}</h1>
    <p>{`Waves have three primary characteristics:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Wavelength (λ)`}</strong>{` — the distance between two consecutive peaks or troughs; the "width" of the wave.`}</li>
      <li parentName="ul"><strong parentName="li">{`Frequency (ν)`}</strong>{` — the number of waves per second that pass by a given point in space. `}</li>
      <li parentName="ul"><strong parentName="li">{`Speed (c)`}</strong>{` — the speed of light is `}<inlineCode parentName="li">{`3.00 * 10^8 m/s`}</inlineCode>{` in a vacuum. `}</li>
    </ul>
    <p>{`All electromagnetic radiation travels at the same velocity — the speed of light. Therefore, the wavelength of a wave multiplied by its frequency always equals the speed of light: `}</p>
    <Equation text="3.00 * 10^8 \ \text{m/s} = \lambda \mu" mdxType="Equation" />
    <h1>{`Light as a Particle and a Wave`}</h1>
    <p>{`Light behaves both as a particle and as a wave. For example, when a ray of light passes through a prism, it spreads out according to its wavelengths. However, light also travels in small particles called photons. `}</p>
    <p>{`Experiments show that the ability of light to cause chemical change depends on its frequency, not its intensity (brightness or strength). Max Planck explained this by assuming that energy comes in packets, called `}<strong parentName="p">{`quanta`}</strong>{`. `}</p>
    <h1>{`The Photoelectric Effect`}</h1>
    <p>{`Each metal has a different energy at which it ejects electrons. At lower energies than this threshold, electrons are not emitted. `}</p>
    <p>{`Einstein concluded that energy is proportional to frequency:`}</p>
    <Equation text="E = h \mu" mdxType="Equation" />
    <p>{`Where `}<em parentName="p">{`h`}</em>{` is Planck's constant, `}<inlineCode parentName="p">{`6.626 * 10^-34 J-s`}</inlineCode>{`. `}</p>
    <h1>{`Electron Configuration`}</h1>
    <h2>{`Principal quantum number`}</h2>
    <p>{`The principal quantum number, or `}<em parentName="p">{`n`}</em>{`, describes the size or distance from the nucleus. This also indicates energy, as further from the nucleus = higher energy. This is known as the "shell". `}</p>
    <h2>{`Sublevels`}</h2>
    <p>{`Each energy level is divided into `}<strong parentName="p">{`sublevels`}</strong>{`, which describe the `}<em parentName="p">{`shape`}</em>{` of orbitals. `}</p>
    <Callout mdxType="Callout">
  The number of sublevels within the energy level is equal to the level's principal quantum number.
    </Callout>
    <p>{`The sublevels have names `}<em parentName="p">{`s`}</em>{`, `}<em parentName="p">{`p`}</em>{`, `}<em parentName="p">{`d`}</em>{`, and `}<em parentName="p">{`f`}</em>{` (in order of increasing energy): `}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`s`}</em>{` orbitals are spherical in shape, and can be oriented in one way. `}</li>
      <li parentName="ul"><em parentName="li">{`p`}</em>{` orbitals have a dumbbell shape, and can be oriented in three ways. `}</li>
      <li parentName="ul"><em parentName="li">{`d`}</em>{` orbitals usually have four lobes, and can be oriented in five ways. `}</li>
      <li parentName="ul"><em parentName="li">{`f`}</em>{` orbitals have a very complicated shape, and can be oriented in seven ways. `}</li>
    </ul>
    <p>{`According to the Pauli Exclusion Principle, no two electrons of the same atom can have exactly the same energy. Therefore, each orbital can hold a maximum of two electrons with opposite spin. `}</p>
    <p>{`Hund's rule dictates that orbitals must all be filled with one electron before a second electron is added. In other words, the electrons don't pair up until they have to. `}</p>
    <h2>{`Orbital diagrams and electron configurations`}</h2>
    <p>{`Orbital diagrams and electron configurations look like this: `}</p>
    <p><img parentName="p" {...{
        "src": "/assets/orbital-diagrams.png",
        "alt": "Orbital diagram and electron configurations for several elements"
      }}></img></p>
    <p>{`They are constructed by counting your way through the periodic table, and imagining the following areas of the periodic table: `}</p>
    <p><img parentName="p" {...{
        "src": "/assets/electron-configuration-table.png",
        "alt": "Periodic table with electron configuration areas overlaid"
      }}></img></p>
    <p><em parentName="p">{`You can make life easier by starting counting at the closest noble gas to the element you're describing, and shortening this noble gas' electron configuration as `}{`[Ar]`}{`, for example. Examples using this notation are shown in later sections.`}</em></p>
    <p>{`Some levels are in an unexpected order (for example, `}<em parentName="p">{`3d`}</em>{` before `}<em parentName="p">{`4p`}</em>{`). This is because, although the 3 shell is closer to the nucleus than the 4 shell, the outer orbitals of the 3 shell are further from the nucleus than the inner orbitals of the 4 shell. `}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`There are certain exceptions to be aware of when finding electron configuration: `}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`Cr`}</inlineCode>{` and `}<inlineCode parentName="li">{`Mo`}</inlineCode>{` would rather have half-filled s- and d- sublevels. For example, `}<inlineCode parentName="li">{`Cr`}</inlineCode>{` is `}<inlineCode parentName="li">{`[Ar] 4s^1 3d^5`}</inlineCode>{`.`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Cu`}</inlineCode>{`, `}<inlineCode parentName="li">{`Ag`}</inlineCode>{`, and `}<inlineCode parentName="li">{`Au`}</inlineCode>{` would rather have half-filled s- sublevels and fully-filled d- sublevels. For example, `}<inlineCode parentName="li">{`Cu`}</inlineCode>{` is `}<inlineCode parentName="li">{`[Ag] 4s^1 3d^10`}</inlineCode>{`. `}</li>
    </ol>
    <h1>{`Charges`}</h1>
    <p>{`When an ion has a charge, the charge is taken off of the highest shell. `}<em parentName="p">{`This is not necessarily the last listed shell in the written electron configuration`}</em>{`. `}</p>
    <p>{`For example, `}<inlineCode parentName="p">{`Fe`}</inlineCode>{` in ground state (normal state) is: `}</p>
    <Equation text="\text{Fe} = [\text{Ar}] \ 4\text{s}^2 \ 3\text{d}^6" mdxType="Equation" />
    <p>{`But `}<inlineCode parentName="p">{`Fe`}</inlineCode>{` with charge `}<inlineCode parentName="p">{`3+`}</inlineCode>{` (three `}<em parentName="p">{`fewer`}</em>{` electrons, since electrons are negative charge) is: `}</p>
    <Equation text="\text{Fe}^{3+} = [\text{Ar}] \ 3\text{d}^5" mdxType="Equation" />
    <h1>{`Valence Electrons`}</h1>
    <p>{`The number of valence electrons an element has is the `}<em parentName="p">{`total`}</em>{` number of electrons in its highest shell. Again, this is not necessarily the last listed shell. `}</p>
    <p>{`For example, `}<inlineCode parentName="p">{`Zn`}</inlineCode>{` has two valence electrons: `}</p>
    <Equation text="[\text{Ar}] = 4\text{s}^\textbf{2} \ 3\text{d}^{10}" mdxType="Equation" />
    <h1>{`Unpaired Electrons`}</h1>
    <p>{`To determine the number of unpaired electrons, think about the way the last listed shell would pair up. `}</p>
    <p>{`For example, the electron configuration for `}<inlineCode parentName="p">{`As`}</inlineCode>{` is: `}</p>
    <Equation text="\text{As} = [\text{Ar}] \ 4\text{s}^2 \ 3\text{d}^{10} \ 4\text{p}^3" mdxType="Equation" />
    <p>{`The only incompletely filled shell is the `}<em parentName="p">{`4p`}</em>{` shell, which looks as follows: `}</p>
    <Equation text="\uparrow \ \ | \uparrow \ \ | \uparrow" mdxType="Equation" />
    <p><em parentName="p">{`A `}</em>{`p`}<em parentName="p">{` level shell can be positioned in three configurations, for a total of six electrons.`}</em></p>
    <p>{`Therefore, it is clear from the diagram that all three electrons in the incomplete `}<em parentName="p">{`4p`}</em>{` shell are unpaired, so `}<inlineCode parentName="p">{`As`}</inlineCode>{` has three unpaired electrons. This makes it `}<strong parentName="p">{`paramagnetic`}</strong>{`, as the next section explains. `}</p>
    <h1>{`Magnetic Properties`}</h1>
    <p>{`Ions with unpaired electrons are `}<strong parentName="p">{`paramagnetic`}</strong>{`, because they the electrons don't balance each other out in opposite directions. Ions `}<em parentName="p">{`without`}</em>{` unpaired electrons are `}<strong parentName="p">{`diamagnetic`}</strong>{`. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      